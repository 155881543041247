<template>
  <div class="chat-message-thread-item" :class="selfClass">
    <span v-if="isSent" class="secondary-text">{{ messageDetails.sender }}</span>
    <div class="chat-message-content">{{ messageDetails.textMessage }}</div>
    <div class="flex flex-row chat-thread-item-details">
      <span v-if="messageStatus" class="secondary-text">{{ messageStatus }}</span>
      <span class="secondary-text ml-1">({{ displayMedium }})</span>
    </div>
  </div>
</template>
  
<script>

import { computed } from '@vue/composition-api';

export default {
  props: {
    message: {
      type: Object,
      required: true
    },
    channel: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const messageDetails = computed(() => props.message);
    const channelDetails = computed(() => props.channel);

    const isSent = computed(() => messageDetails.value.sender !== channelDetails.value?.patientFullName);
    const messageStatus = computed(() => {
      if (!isSent.value) { 
        return ''
      }
      switch (messageDetails.value.status?.toLowerCase()) {
        case 'delivered':
          return 'Delivered';
        case 'sent':
          return 'Sent';
        case 'accepted' || 'sending' || 'queued':
          return 'Sending...';
        case 'scheduled':
          return 'Scheduled';
        case 'canceled': 
          return 'Canceled';
        case 'read':
          return 'Read';
        case 'failed' || 'undelivered':
          return 'Failed';
        default:
          return '';
      }
    })
    const selfClass = computed(() => `${isSent.value ? 'sent' : 'received'} ${messageStatus.value === 'Failed' ? 'failed' : ''}` )
    const displayMedium = computed(() => {
      switch (messageDetails.value.medium?.toLowerCase()) {
        case 'sms':
          return 'SMS';
        case 'websocket':
          return 'HUB';
        case 'inapp':
          return 'HUB';
        default:
          return 'SMS';
      }
    })

    return {
      messageDetails,
      channelDetails,
      isSent,
      messageStatus,
      selfClass,
      displayMedium,
    }
  },
}
</script>
<style lang="scss" scoped>
.chat-message-thread-item {
  display: flex;
  flex-direction: column;
  margin: 6px 0;

  .secondary-text {
    opacity: 0.5;
  }
}

.chat-message-content {
  margin: 2px 0;
  padding: 4px 10px;
  border-radius: 12px;
  width: fit-content;
  max-width: 280px;
}

.sent {
  align-self: flex-end;
  .secondary-text {
    align-self: flex-end;
  }

  .chat-message-content {
    align-self: flex-end;
    background-color: #99CCFF;
  }

  .chat-thread-item-details {
    justify-content: flex-end;
  }
}

.received {
  .chat-message-content {
    background-color: #D3D3D3;
  }
}

.failed {
  .chat-message-content {
    background-color: #FFE7E6;
  }
}
</style>
