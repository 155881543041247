export const state = () => ({
  loading: false,
  todayItems: {},
  updatingNoteStatuses: {},
  addingNoteStatuses: {},
});

export const mutations = {
  SET_LOADING (state, loading) {
    state.loading = loading;
  },
  SET_UPDATING_NOTE_STATUS (state, { noteId, outcome }) {
    state.updatingNoteStatuses = {
      ...state.updatingNoteStatuses,
      [noteId]: outcome,
    }
  },
  SET_ADDING_NOTE_STATUS (state, { entryId, outcome }) {
    state.addingNoteStatuses = {
      ...state.addingNoteStatuses,
      [entryId]: outcome,
    }
  },
  CLEAR_UPDATING_NOTE_STATUS (state, noteId) {
    delete state.updatingNoteStatuses[noteId];
  },
  CLEAR_ADDING_NOTE_STATUS (state, entryId) {
    delete state.addingNoteStatuses[entryId];
  },
  SET_TODAY_ITEMS (state, todayItems) {
    state.todayItems = todayItems
  },
  UPDATE_TODAY_ITEM (state, { entryType, updatedTodayItem }) {
    const todayItemIndex = state.todayItems[entryType]?.findIndex(item => item.id === updatedTodayItem.id);
    if (todayItemIndex !== -1) {
      state.todayItems[entryType].splice(todayItemIndex, 1, updatedTodayItem);
    }
  },
  ADD_NOTE (state, { todayType, newNote }) {
    const todayKey = todayType === 'CARE_EVENT' ? 'adverseEvent' : 'appointment';
    const todayItemIndex = state.todayItems[todayType]?.findIndex(item => item.id === newNote[todayKey].id);
    if (todayItemIndex !== -1) {
      state.todayItems[todayType][todayItemIndex].notes.push(newNote);
    }
  },
  UPDATE_NOTE (state, { todayType, updatedNote }) {
    const todayItemIndex = state.todayItems[todayType]?.findIndex(item => item.notes?.some(note => note.id === updatedNote.id));
    if (todayItemIndex !== -1) {
      const noteIndex = state.todayItems[todayType][todayItemIndex].notes.findIndex(note => note.id === updatedNote.id);
      if (noteIndex !== -1) {
        state.todayItems[todayType][todayItemIndex].notes.splice(noteIndex, 1, updatedNote);
      }
    }
  },
  DELETE_NOTE (state, { todayType, noteId }) {
    const todayItemIndex = state.todayItems[todayType]?.findIndex(item => item.notes?.some(note => note.id === noteId));
    if (todayItemIndex !== -1) {
      state.todayItems[todayType][todayItemIndex].notes = state.todayItems[todayType][todayItemIndex].notes.filter(note => note.id !== noteId);
    }
  },
};

export const actions = {
  setLoading ({ commit }, value) {
    commit('SET_LOADING', value);
  },
  setUpdatingNoteStatus ({ commit }, value) {
    commit('SET_UPDATING_NOTE_STATUS', value);
  },
  setAddingNoteStatus ({ commit }, value) {
    commit('SET_ADDING_NOTE_STATUS', value);
  },
  clearUpdatingNoteStatus ({ commit }, value) {
    commit('CLEAR_UPDATING_NOTE_STATUS', value);
  },
  clearAddingNoteStatus ({ commit }, value) {
    commit('CLEAR_ADDING_NOTE_STATUS', value);
  },
  async fetchTodayItems ({ commit }, { params }) {
    commit('SET_LOADING', true);
    try {
      const { data: fetchedTodayItems } = await this.$apiv2.getTodayItems(params);
      const newTodayItems = {};
      // const fetchedTodayItems = [];
      fetchedTodayItems.forEach(item => {
        if (newTodayItems[item.type]) {
          newTodayItems[item.type].push(item);
        } else {
          newTodayItems[item.type] = [item];
        }
      })
      commit('SET_TODAY_ITEMS', { ...newTodayItems });
    } catch (err) {
      console.error(err);
    }
    commit('SET_LOADING', false);
  },
  async updateAppointment ({ commit }, { appointmentId, appointmentData, entryType = 'APPOINTMENT' }) {
    const appointmentPayload = { ...appointmentData, id: appointmentId };
    const updatedAppointment = await this.$apiv2.updateAppointment(appointmentId, appointmentPayload);
    commit('UPDATE_TODAY_ITEM', { entryType, updatedTodayItem: updatedAppointment });
    return updatedAppointment;
  },
  async addNoteToAppointment ({ commit }, { appointmentId, notePayload, todayType = 'APPOINTMENT' }) {
    const newNote = await this.$apiv2.addAppointmentNote(appointmentId, notePayload);
    commit('ADD_NOTE', { todayType, newNote });
    return newNote;
  },
  async updateNoteOnAppointment ({ commit }, { noteId, notePayload, todayType = 'APPOINTMENT' }) {
    const updatedNote = await this.$apiv2.updateAppointmentNote(noteId, notePayload);
    commit('UPDATE_NOTE', { todayType, updatedNote });
    return updatedNote;
  },
  async deleteNoteOnAppointment ({ commit }, { noteId, todayType = 'APPOINTMENT' }) {
    await this.$apiv2.deleteAppointmentNote(noteId);
    commit('DELETE_NOTE', { todayType, noteId });
  },
  async addNoteToEvent ({ commit }, { eventId, notePayload, todayType = 'CARE_EVENT' }) {
    const newNote = await this.$apiv2.addAdverseEventNote(eventId, notePayload);
    commit('ADD_NOTE', { todayType, newNote });
    return newNote;
  },
  async updateNoteOnEvent ({ commit }, { noteId, notePayload, todayType = 'CARE_EVENT' }) {
    const updatedNote = await this.$apiv2.updateAppointmentNote(noteId, notePayload);
    commit('UPDATE_NOTE', { todayType, updatedNote });
    return updatedNote;
  },
  async deleteNoteOnEvent ({ commit }, { noteId, todayType = 'CARE_EVENT' }) {
    await this.$apiv2.deleteAppointmentNote(noteId);
    commit('DELETE_NOTE', { todayType, noteId });
  },
};

export const getters = {
  getUpdatingNoteStatus: (state) => (noteId) => state.updatingNoteStatuses[noteId],
  getAddingNoteStatus: (state) => (entryId) => state.addingNoteStatuses[entryId],
  getTodayItemsByType: (state) => (type) => state.todayItems[type] || [],
  getCountByType: (_, getters) => (type) => getters.getTodayItemsByType(type).length,
  appointments: (_, getters) => getters.getTodayItemsByType('APPOINTMENT'),
  tasks: (_, getters) => getters.getTodayItemsByType('TASK'),
  events: (_, getters) => getters.getTodayItemsByType('CARE_EVENT'),
  pendingAppointments: (_, getters) => getters.appointments.filter(item => item.status === 'PENDING'),
  pendingTasks: (_, getters) => getters.tasks.filter(item => item.status === 'PENDING'),
  potentialEvents: (_, getters) => getters.events.filter(item => item.actuality === 'POTENTIAL'),
  appointmentById: (_, getters) => (appointmentId) => getters.appointments.find(item => item.id === appointmentId),
  taskById: (_, getters) => (taskId) => getters.tasks.find(item => item.id === taskId),
  eventById: (_, getters) => (eventId) => getters.events.find(item => item.id === eventId),
};
