<template>
  <div :class="`flex vertical full-height overflow-hidden ${isMaximized ? 'chat-summary pt-5 pr-6 pb-0 pl-0' : ''}`">
    <div v-if="isMaximized" class="chat-header flex-row align-items-center font-medium">
      <span>Messages</span>
    </div>
    <div class="field mt-3">
      <span class="p-float-label">
        <Dropdown id="siteFilter" filter v-model="selectedSite" class="site-dropdown w-full" :options="sitesSortedByFullName"
          optionLabel="full_name" @show="dropdown_showHandler" showClear />
        <label for="siteFilter">Site</label>
      </span>
    </div>
    <div class="field">
      <span class="p-float-label">
        <Dropdown id="assignedTo" v-model="channelOwner" class="site-dropdown w-full" :options="ownerOptions"
          optionLabel="label" optionValue="value" />
        <label for="assignedTo">Assigned to</label>
      </span>
    </div>

    <InlineMessage v-if="fetchError" class="flex flex-grow-0 justify-content-start align-items-center" severity="error">{{fetchError.message || 'Chat service has encountered an error'}}</InlineMessage>
    
    <div class="chat-message-list">
      <ChatSidebarMessageListItem v-for="(channel, index) in filteredChannels" :channel="channel"
        @openChannel="openChannel_clickHandler" :key="index" />
    </div>
  </div>
</template>
  
<script>

import ChatSidebarMessageListItem from "@/components/layout/sidebar/sidebar-panels/chat/ChatSidebarMessageListItem";
import { OWNER } from "./constants";
import { setupScrollDetection } from "@/helpers";
import { ref, computed, onMounted, watch } from '@vue/composition-api';


export default {
  components: {
    ChatSidebarMessageListItem,
  },
  props: {
    maximized: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { root, emit }) {
    const selectedSite = ref(undefined);
    const channelOwner = ref(null);

    const isMaximized = computed(() => props.maximized);

    // Store State and Getters
    const channels = computed(() => root.$store.state.messaging.channels);
    const selectedChannelOwner = computed(() => root.$store.state.messaging.selectedChannelOwner);
    const fetchError = computed(() => root.$store.state.messaging.fetchError);
    const sitesSortedByFullName = computed(() => root.$store.getters['portal/sitesSortedByFullName']);

    const changeSelectedChannelOwner = (payload) => root.$store.dispatch('messaging/changeSelectedChannelOwner', payload);

    const ownerOptions = [
        { label: 'All', value: OWNER.ALL },
        { label: 'Myself', value: OWNER.ME },
        { label: 'Others', value: OWNER.OTHER },
    ]

    const filteredChannels = computed(() => {
      const filterBySelectedSite = (channel) =>
        !selectedSite.value || channel.tenants.includes(selectedSite.value.id);

      return channels.value.filter(filterBySelectedSite).reverse();
    })

    const openChannel_clickHandler = (channel) => {
      emit('openChannel', channel);
    }

    const dropdown_showHandler = () => {
      setupScrollDetection('.p-dropdown-items-wrapper', 'show-scrollbar');
    }

    onMounted(() => {
      channelOwner.value = selectedChannelOwner.value;
      setupScrollDetection('.chat-message-list', 'show-scrollbar');
    })

    watch(channelOwner, (val) => {
      changeSelectedChannelOwner(val)
    })

    return {
      selectedSite,
      channelOwner,
      isMaximized,
      ownerOptions,
      filteredChannels,
      fetchError,
      sitesSortedByFullName,
      openChannel_clickHandler,
      dropdown_showHandler,
    }
  },
}
</script>
<style lang="scss" scoped>
.chat-summary {
  max-width: 400px;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2);
}

.chat-header {
  display: flex;
  font-size: 24px;
}

.site-dropdown {
  ::v-deep .p-dropdown-panel {
    max-width: 100%;
    overflow-x: auto;
  }
  
  ::v-deep .p-dropdown-item {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
  }
  ::v-deep .p-dropdown-clear-icon {
    top: 43%;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::v-deep .show-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
  }
  ::v-deep .show-scrollbar::-webkit-scrollbar-thumb {
    background: #609af8;
  }
}

.chat-message-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  overflow-y: auto;
}

::v-deep .p-tabview,
.p-tabview-panel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
  overflow: hidden;

  .p-tabview-panels {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding: 0;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.show-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #609af8;
}
</style>