import _ from 'lodash';
import deepClone from 'deep-clone';
import {FIELD_STATUS_UNUSABLE} from "@/components/spirometry/report/ReportConstants";

export default class SpirometrySession {
    constructor(session, transations = {}) {
        // Shortcuts
        this.id = session.id;
        this.performedAt = session.performed_at;
        this.raw = session;
        this.transations = transations;
        this.ats2019 = session.analysis.spiro_session_summary;

        this.overwrite = new SpirometryOverwrite(session);

        // Enrich each test with index
        this.ats2019.tests_values.forEach((test, index) => {
            test.index = index;
        });

        // Internal map for quick access to tests
        this._testsMap = this.ats2019.tests_values.reduce((acc, test) => {
            acc[test.id] = test;
            return acc;
        }, new Map());
    }

    get tests() {
        return this.ats2019.tests_values;
    }

    getTestById = (testId) => this._testsMap[testId];
    getTestByIndex = (index) => this.tests[index];

    overwrittenBestTestId = () => this.overwrite.bestTestId;
    overwrittenBestFvcTestId = () => this.overwrite.bestFvcTestId;
    overwrittenBestFev1TestId = () => this.overwrite.bestFev1TestId;
    overwrittenBestPefTestId = () => this.overwrite.bestPefTestId;

    isTestBestFvc = (test) => this.overwrittenBestFvcTestId() === test.id;
    isTestBestFev1 = (test) => this.overwrittenBestFev1TestId() === test.id;
    isTestBestPef = (test) => this.overwrittenBestPefTestId() === test.id;

    isTestBest = (test) => this.overwrittenBestTestId() === test.id;
    isTestBad = (test) => this.overwrite.getTestFvcStatus(test.id) === 'unusable' && this.overwrite.getTestFev1Status(test.id) === 'unusable';
    bestTestId = () => this.overwrittenBestTestId() || this.calculatedBestTestId();
    bestTest = () => this.getTestById(this.bestTestId());

    calculatedBestTestId = () => this.ats2019.feedback.final.best_test_id;

    hasOverwrittenBestTest = () => this.overwrittenBestTestId()
      ? this.overwrittenBestTestId() !== this.calculatedBestTestId()
      : false;

    hasOverwrittenBestFvcTest = () => !!this.overwrittenBestFvcTestId()
    hasOverwrittenBestFev1Test = () => !!this.overwrittenBestFev1TestId()
    hasOverwrittenBestPefTest = () => !!this.overwrittenBestPefTestId()

    hasOverwrittenFev1 = () => this.hasOverwrittenBestFev1Test() || this.hasOverwrittenBestTest();
    hasOverwrittenFvc = () => this.hasOverwrittenBestFvcTest() || this.hasOverwrittenBestTest();
    hasOverwrittenPef = () => this.hasOverwrittenBestPefTest() || this.hasOverwrittenBestTest();
    hasOverwrittenFev1Fvc = () => this.hasOverwrittenBestFev1Test() || this.hasOverwrittenBestFvcTest() || this.hasOverwrittenBestTest();


    bestFvcValue() {
        const testId = this.overwrittenBestFvcTestId() || this.overwrittenBestTestId();
        return testId
          ? this.getTestById(testId).measured_values.fvc_measured
          : this.ats2019.best_measured_values.final.fvc_measured;
    }

    bestFev1Value() {
        const testId = this.overwrittenBestFev1TestId() || this.overwrittenBestTestId();
        return testId
          ? this.getTestById(testId).measured_values.fev1_measured
          : this.ats2019.best_measured_values.final.fev1_measured;
    }

    bestPefValue() {
        const testId = this.overwrittenBestPefTestId() || this.overwrittenBestTestId();
        return testId
          ? this.getTestById(testId).measured_values.pef_measured
          : this.ats2019.best_measured_values.final.pef_measured;
    }

    bestFev1FvcValue() {
        const bestFev1 = this.bestFev1Value();
        const bestFvc = this.bestFvcValue();
        return bestFev1 && bestFvc ? bestFev1 / bestFvc : undefined;
    }

    bestGenericValue(name) {
      const testId = this.overwrittenBestTestId();
      return testId
        ? this.getTestById(testId).measured_values[name]
        : this.ats2019.best_measured_values.final[name];
    }

    bestFev05Value = () => this.bestGenericValue('fev05_measured');
    bestFev075Value = () => this.bestGenericValue('fev075_measured');
    bestFev6Value = () => this.bestGenericValue('fev6_measured');
    bestFef25Value = () => this.bestGenericValue('fef25_measured');
    bestFef50Value = () => this.bestGenericValue('fef50_measured');
    bestFef75Value = () => this.bestGenericValue('fef75_measured');
    bestFef2575Value = () => this.bestGenericValue('fef2575_measured');

    predictedValue = (name) => this.ats2019.human_values.predicted_values[name];
    relativeValue = (name) => this.ats2019.relative_to_predicted_values.final[name];

    fvcGrading = () => this.ats2019.session_grading_summary.final.session_grading_fvc;
    fev1Grading = () => this.ats2019.session_grading_summary.final.session_grading_fev1;

    durationExhale = () => this.bestGenericValue('duration_exhale');

    // Reports

    isOverwritten = () => this.raw.session_overwrite !== undefined;
    get overwriting() {
        return this.raw.session_overwrite;
    }

    fvcZscore = () => this.relativeValue('fvc_z_score');
    fev1Zscore = () => this.relativeValue('fev1_z_score');
    fev1fvcZscore = () => this.relativeValue('fev1fvc_z_score');

    testName(test) {
        if (test.id === this.calculatedBestTestId()) {
          return `Test ${test.index + 1} (Best)`;
        }
        return `Test ${test.index + 1}`;
    }

    interpretationDisplay() {
        return this.transations.session_interpretation;
    }

    fvcGradingDisplay() {
        return this.transations.fvc_grading || this.fvcGrading()
    }

    fev1GradingDisplay() {
        return this.transations.fev1_grading || this.fev1Grading()
    }

    sessionReasonDisplay() {
        return this.transations.session_reason;
    }

    notesDisplay() {
        return this.raw.notes;
    }

    hasUnusableValues() {
        return this.tests.some(
            test => test.feedback.fvc.status_final === FIELD_STATUS_UNUSABLE
                || test.feedback.fev1.status_final === FIELD_STATUS_UNUSABLE
        );
    }

    get errorCodes() {
        // Return a set of all error codes from all tests
        return this.tests
            .reduce((acc, test) => {
                const codes = [test.feedback.fvc.errors_display, test.feedback.fev1.errors_display]
                    .join(',')
                    .split(',')
                    .map(code => code.trim())
                    .filter(code => code !== '')
                return acc.concat(codes);
                }, []
            )
            .filter((value, index, self) => self.indexOf(value) === index);
  }
}

class SpirometryOverwrite {
    constructor(session) {
        this.values = {
            // defaults
            best_test_id: session.analysis.spiro_session_summary.feedback.final.best_test_id,
            best_fvc_test_id: session.analysis.spiro_session_summary.feedback.final.best_fvc_test_id,
            best_fev1_test_id: session.analysis.spiro_session_summary.feedback.final.best_fev1_test_id,
            best_pef_test_id: session.analysis.spiro_session_summary.feedback.final.best_pef_test_id,
            test_overwrites: session.analysis.spiro_session_summary.tests_values.map(t => ({
                test_id: t.id,
                fvc_status: t.feedback.fvc.status_final,
                fvc_comment: '',
                fev1_status: t.feedback.fev1.status_final,
                fev1_comment: '',
            })),
            // overwrite from session
            ...deepClone(session.session_overwrite)
        };
        this.loadedValues = deepClone(this.values);
        this.changed = false;
        this.canSave = false;
        this.error = undefined;
    }

    valuesForUpdate() {
        return {
            best_test_id: this.values.best_test_id,
            best_fvc_test_id: this.values.best_fvc_test_id,
            best_fev1_test_id: this.values.best_fev1_test_id,
            best_pef_test_id: this.values.best_pef_test_id,
            test_overwrites: this.values.test_overwrites.map(t => ({
                test_id: t.test_id,
                fvc_status: t.fvc_status,
                fev1_status: t.fev1_status,
            }))
        };
    }

    discardChanges() {
        this.values = deepClone(this.loadedValues);
        this.changed = false;
        this.canSave = false;
        this.error = undefined;
    }

    validate() {
        this.error = undefined;
        this.changed = !(_.isEqual(this.values, this.loadedValues));
        this.canSave = this.changed && !this.error;
    }

    get bestTestId() {
        return this.values.best_test_id;
    }

    set bestTestId(value) {
        this.values.best_test_id = value;
        if (!this.values.best_fev1_test_id) {
            this.values.best_fev1_test_id = value;
        }
        if (!this.values.best_fvc_test_id) {
            this.values.best_fvc_test_id = value;
        }
        if (!this.values.best_pef_test_id) {
            this.values.best_pef_test_id = value;
        }
        this.validate();
    }

    get bestFvcTestId() {
        return this.values.best_fvc_test_id;
    }

    set bestFvcTestId(value) {
        this.values.best_fvc_test_id = value;
        this.validate();
    }

    get bestFev1TestId() {
        return this.values.best_fev1_test_id;
    }

    set bestFev1TestId(value) {
        this.values.best_fev1_test_id = value;
        this.validate();
    }

    get bestPefTestId() {
        return this.values.best_pef_test_id;
    }

    set bestPefTestId(value) {
        this.values.best_pef_test_id = value;
        this.validate();
    }

    getTestFvcStatus(testId) {
        const test = this.values.test_overwrites.find(t => t.test_id === testId);
        return test ? test.fvc_status : null;
    }

    setTestFvcStatus(testId, value) {
        const test = this.values.test_overwrites.find(t => t.test_id === testId);
        if (test) {
            test.fvc_status = value;
            this.validate();
        }
    }

    getTestFev1Status(testId) {
        const test = this.values.test_overwrites.find(t => t.test_id === testId);
        return test ? test.fev1_status : null;
    }

    setTestFev1Status(testId, value) {
        const test = this.values.test_overwrites.find(t => t.test_id === testId);
        if (test) {
            test.fev1_status = value;
            this.validate();
        }
    }
}